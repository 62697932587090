/* EVENTS PAGE*/
.EventsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 100%;
}

.EventsPageWeb {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 285px;
}

.MarginTop50 {
  margin-top: 50px;
}

.MarginTop10 {
  margin-top: 10px;
}

.MarginTop15 {
  margin-top: 15px;
}

.IntroEvents {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 39px;
}

.EventSettingsForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 284px;
  margin: 15px;
  border-radius: 10px;
}

.EventSettingsDate{
  display: flex;
}
.EventOptionValue{
  margin-right: 0px !important;
}

.LogoDesktop {
  display: none;
}

.MuiPagination-outlined {
  color: "#FFF"
}

@media only screen and (min-width: 900px) {
  /* EVENTS BIG SCREEN */
  .EventsPage {
    margin-top: 70px;
    color: white;
  }

  .LogoDesktop {
    display: flex;
    position: fixed;
    top: 74px;
    left: 10%;
    width: 103px;
    height: 90px;
  }
  
  .EventsPageWeb {
    padding: 49px 87px 71px 87px;
    background: #303030;
    border-radius: 10px;
    color: white;
  }
}
