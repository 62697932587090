.RoundsCollapsible {
  width: 285px;
  height: 39px;
  left: 53px;
  top: 197px;

  background: #383838;
}

.Collapsible {
  margin-bottom: 32px;
}

.PlayerForm {
  display: flex;
}

.is-closed .RoundArrow {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  rotate: 90deg;
  transition-delay: 200ms;
}

.RoundArrow {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  transition-delay: 200ms;
}

.SettingsIcon {
  width: 18px;
  height: 18px;
}

.EmptyList {
  display: flex;

  align-items: center;
  justify-content: center;

  margin-top: 10px;

  width: 100%;
  height: 21px;
  left: 51px;
  top: 208px;

  background: rgba(255, 209, 92, 0.35);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffd15c;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}