.PastEvents {
    display: flex;
    align-self: start;
    font-size: 12px;
    font-weight: 600;
    color: #ffd15c;
    margin-top: 24px;
    margin-bottom: 19px;
}

.ProfileHeader {
    font-weight: 400;
    font-size: 20px;
}

.ProfileUsername {
    font-weight: 400;
    font-size: 12px;
}

.UserEventsCollapsible {
    padding: 6px 12px;
    background-color: #383838;
}

.is-closed .ProfileArrow {
    width: 14px;
    height: 14px;
    rotate: 90deg;
    transition-delay: 200ms;
}

.ProfileArrow {
    width: 14px;
    height: 14px;
    transition-delay: 200ms;
}

.EditProfileLink {
    color: #ffd15c;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.ProfileDescription {
    display: flex;
    overflow-wrap: anywhere;
    width: 100%;
    max-width: 350px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 31px;
}
