/* REGISTER PAGE */
.CreateEventPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 100%;
}

.CreateEventPageWeb {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 285px;
  margin-bottom: 11px;
}

.IntroCreateEvent {
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-bottom: 38px;
}

.CreateEventDateDiv {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #c1c1c1;
  margin-bottom: 11px;
}

.CreateEventDate {
  width: 284px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.EventOption {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.EventOptionLabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #929292;
}

.EventOption > div:last-of-type {
  margin-left: auto;
}

@media only screen and (min-width: 900px) {
  /* REGISTER BIG SCREEN */
  .CreateEventPage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    color: white;
  }
  .CreateEventPageWeb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 45px 157px 27px 157px;
    left: 513px;
    top: 274px;
    background: #303030;
    border-radius: 10px;
    color: white;
  }
}
