.TabPlayers {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AdminApproval {
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: 21px;
  left: 51px;
  top: 208px;

  background: rgba(255, 209, 92, 0.35);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffd15c;
}

.PlayEventNavbar {
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: space-between;
}

.PlayEventNavbarOption {
  display: flex;
  padding: 0px;
  padding-bottom: 4px;
  background-color: transparent;
}

.PlayEventNavbarOptionNotSelected {
  border: 0px;
  color: #ffffff;
}

.PlayEventNavbarOptionSelected {
  color: #ffd15c;
  border: 0px;

  border-bottom: 1px #ffd15c solid;
}

.PendingCell {
  width: 61px;
  height: 15px;
  left: 277px;
  top: 257px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #c1c1c1;
}

.JoinedCell {
  width: 44px;
  height: 15px;
  left: 294px;
  top: 306px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffd15c;
}

.TablePlayers {
  display: flex;
  flex-direction: column;

  margin-top: 24px;
}

.TableTeams {
  display: flex;
  flex-direction: column;

  margin-top: 24px;
}

.TablePlayersRow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
}

.TableTeamsRow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
}

.TableTeamsRow > div:first-of-type {
  margin-right: auto;
}

.AddPlayerToTeamForm{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 284px;
  margin: 15px;
  border-radius: 10px;
}

.TableCell {
  text-decoration: none;
  color: #ffffff;
  font-size: 12px;
}

.TablePlayersRow > div:last-of-type {
  margin-left: auto;
}

.RowButtons {
  display: flex;
  width: 144px;
  justify-content: space-between;
}

.RowButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 8px 7px;
  margin: 0px;
}

.ArrowButton {
  padding-left: 7px;
}

.RowButtonAccept {
  color: #262626;
  background-color: #ffd15c;
}

.RowButtonDecline {
  color: #ffd15c;
  background-color: #383838;
  border: 1px #ffd15c solid;
}

.RemovePlayerX {
  margin-left: 12px;
  display: flex;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
}

.PlayerInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}

.TeamInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}

.PlayersInfoCollapsible {
  padding: 0px 0px;
}