/* LOGIN PAGE */
.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 100%;
}

.LoginPageForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 285px;
}

.MarginTopLogin {
  margin-top: 74px;
}

.LogoImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.LogoImage > img {
  width: 103px;
  height: 90px;
}

.IntroLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.WelcomeBackDiv {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.LogIntoAccountDiv {
  margin-bottom: 43px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.ButtonSizes {
  width: 100%;
  height: 52px;
}

.NoUnderLineLinks {
  text-decoration: none;
}

a.ForgotPasswordLink {
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-style: italic;
  font-size: 12px;
  color: rgba(255, 209, 92, 0.8);
  text-decoration: none;
}

.DontHaveAnAccount {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  margin-top: 41px;
  color: #c1c1c1;
}

@media only screen and (min-width: 500px) {
  /* LOGIN BIG SCREEN */
  .MarginTopLogin {
    margin-top: 40px;
  }

  .LoginPageForm {
    padding: 49px 87px 51px 87px;
    background: #303030;
    border-radius: 10px;
  }
  .LogoImage {
    margin-bottom: 40px;
  }
}
