/* REGISTER PAGE */
.RegisterPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 100%;
}

.IntroRegister {
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-bottom: 38px;
}

.RegisterPageForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 285px;
}

.BirthdateRegister {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AlreadyHaveAnAccount {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  margin-top: 20px;
  color: #c1c1c1;
}

.BirthdateRegisterLabel {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #c1c1c1;
  margin-bottom: 11px;
}

.PaddingTopRegister {
  margin-top: 70px;
}

@media only screen and (min-width: 500px) {
  /* REGISTER BIG SCREEN */
  .RegisterPageForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 49px 87px 51px 87px;
    background: #303030;
    border-radius: 10px;
    color: white;
  }

  .AlreadyHaveAnAccount {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    margin-top: 41px;
    color: #c1c1c1;
  }
}
