/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

html,
body,
#app,
#app > div {
    height: 100%;
}

body {
    min-height: 100vh;
    background-color: #262626;
    display: flex;
    flex-direction: column;
}

#root {
    height: 100%;
}

.FormCss {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.PrivacyPolicy {
    margin-top: auto;
    padding-top: 35px;
    padding-bottom: 35px;
    color: #c1c1c1;
    font-size: 12px;
    text-align: center;
}

.NormalFont {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.footer {
    margin-top: auto;
}

.ErrorStyle {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #ff5c5c;
    margin-top: -12px;
    margin-bottom: 7px;
}

a:hover {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

button:hover {
    cursor: pointer;
}

.TermsAndConditions {
    color: white;

    align-items: flex-start;
    justify-content: flex-start;
    font-size: 10px;
}

.LogoImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.LogoImage > a > img {
    width: 103px;
    height: 90px;
}
